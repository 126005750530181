<template>
  <div style="width: 100%">
    <bread-crumb :breadcrumb-items="breadcrumbItems" />
    <div class="container default-padding ">
      <el-row :gutter="40" class="section-padding">
        <el-col :span="24" :lg="10" class="mb-8">
          <div class="primary-heading mb-4">
            {{ labels.PH_FIND_US }}
          </div>

          <div class="find-us">
            <div class="mb-4">
              <div class="caption">
                {{ labels.LBL_OFFICE }}
              </div>

              <div class="mb-1 disabled">{{ labels.PH_VISIT_US }}</div>

              <div>
                <a href="https://www.google.com/maps" target="_blank">
                  <icon-base class="mr-2">
                    <icon-location />
                  </icon-base>
                  {{ company && company.address1 }}
                </a>
              </div>

              <div class="ml-4">
                {{ company && company.address2 }}
              </div>

              <div class="ml-4">{{ company && company.postal }}</div>
            </div>

            <div class="mb-4">
              <div class="caption ">
                {{ labels.LBL_DIRECT_CONTACT }}
              </div>

              <div class="mb-1 disabled">{{ labels.PH_CUSTOMER_SUPPORT }}</div>

              <el-row type="flex" align="middle">
                <icon-base class="mr-2">
                  <icon-phone />
                </icon-base>

                <a :href="`tel:${company.phone}`">
                  {{ company && company.phone }}
                </a>
              </el-row>

              <el-row type="flex" align="middle">
                <icon-base class="mr-2">
                  <icon-mail />
                </icon-base>

                <a :href="`mailto:${company.email}`">
                  {{ company && company.email }}
                </a>
              </el-row>
            </div>
          </div>
        </el-col>
        <!-- Contact us Form  -->
        <el-col :span="24" :lg="14">
          <div class="primary-heading mb-4">
            {{ labels.PH_START_CONVERS }}
          </div>

          <feedback-form />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { labels } from "@/common";

  import IconBase from "@/components/IconBase.vue";
  import IconMail from "@/components/icons/IconMail.vue";
  import IconPhone from "@/components/icons/IconPhone.vue";
  import IconLocation from "@/components/icons/IconLocation.vue";
  import FeedbackForm from "@/components/FeedbackForm.vue";
  import BreadCrumb from "../components/BreadCrumb.vue";

  export default {
    name: "ContactUs",

    components: {
      IconBase,
      IconMail,
      IconPhone,
      IconLocation,
      FeedbackForm,
      BreadCrumb,
    },

    data() {
      return {
        labels,
      };
    },

    computed: {
      ...mapState({
        company: (state) => state.organization.orgDetail,
      }),

      breadcrumbItems() {
        return [
          {
            name: "Contact Us",
            path: "",
          },
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .find-us {
    background: $background-color;
    padding: 1rem;
    border-radius: 3px;

    & > div {
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: black;

      &:hover {
        color: $primary-color;
      }
    }
  }
</style>
